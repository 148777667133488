import React, { useState } from 'react'

type Props = {}

const Footer = (props: Props) => {
// usestate


return (
<div
    className="flex flex-row items-center justify-between py-3 text-xs text-white border-b-4 md:text-sm Footer bg-background-800 border-primary-500">
    <span>
        
    </span>
    <span className='FooterText'>
        Designed and Developed by <span className='text-xl'>☕</span> & Me
    </span>
    <span className='p-2 mr-2 rounded-full bg-primary-500 hover:cursor-pointer hover:bg-primary-600' onClick={() => window.scrollTo(0,0)}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
        </svg>
    </span>
    

</div>
);
}

export default Footer;
