import { useState, useRef, Ref } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import Swal from "sweetalert2";

type Props = {
  sectionRef?: Ref<HTMLDivElement>;
};

const ContactMe = (props: Props) => {
  // usestate
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
  const [error, setError] = useState("");
  const [messageSending, setMessageSending] = useState(false);
  const recaptchaRef = useRef(null);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const showSwalWithResponse = (result: any) => {
    let toast_title = "";
    let toast_text = "";
    if (result === "success") {
      toast_title = "Message Sent!";
      toast_text = "Will get back to you asap";
      setName("");
      setEmail("");
      setMessage("");
    } else {
      toast_title = "Error!";
      toast_text = "Your message was not sent";
    }
    Toast.fire({
      icon: result,
      title: toast_title,
      text: toast_text,
    });
  };

  const handleFormSubmit = (event: any) => {
    if (captchaResponse) {
      setMessageSending(true);
      event.preventDefault();
      axios({
        method: "post",
        url: "https://backend.prajanacharya.com.np/api/message/post",
        withCredentials: false,
        data: {
          name: name,
          email: email,
          message: message,
          recaptcha_response: captchaResponse,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          //@ts-ignore
          recaptchaRef.current.reset(); // here
          setMessageSending(false);
          if (response.data.status === "success") {
            showSwalWithResponse("success");
          } else {
            showSwalWithResponse("error");
          }
          // }
          console.log(response.data.message);
        })
        .catch(function (error) {
          setMessageSending(false);
          console.log(error);
        });
    } else {
      setError("Please verify you are a human first!!!");
    }
  };

  return (
    <div
      className="lg:pb-0 lg:h-fit lg:min-h-screen bg-background-750 ContactMe"
      id="ContactMe"
      ref={props.sectionRef}
    >
      <div className="container flex flex-col items-center h-full p-3 mx-auto lg:w-4/5 pb-7 lg:pb-7">
        <div
          className="flex py-12 text-2xl text-white md:text-4xl md:py-20"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
        >
          <h1 className="relative tracking-widest ContactMeHeading PageHeading">
            Contact Me
          </h1>
        </div>

        <div
          className="flex flex-col justify-center w-full shadow-2xl xl:flex-row contactDetailsContainer"
          data-aos="fade-left"
          data-aos-delay="500"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          {/* My Contact Details */}
          <div className="flex flex-col w-full px-4 pt-2 pb-6 bg-opacity-90 bg-primary-500 xl:w-2/6 gap-y-4 ContactDetails">
            <h3 className="mb-2 text-base font-bold text-white underline md:text-xl underline-offset-8 decoration-white">
              My Contact Details:
            </h3>
            <div className="flex flex-row lg:gap-x-5 gap-x-2 EmailAddress">
              <span className="svgIcon">
                <a href="mailto:prajanacharya11@gmail.com">
                  <svg
                    viewBox="0 0 128 128"
                    className="w-6 h-6 md:w-8 md:h-8 fill-white stroke-white"
                  >
                    <path d="M44.59 4.21a63.28 63.28 0 004.33 120.9 67.6 67.6 0 0032.36.35 57.13 57.13 0 0025.9-13.46 57.44 57.44 0 0016-26.26 74.33 74.33 0 001.61-33.58H65.27v24.69h34.47a29.72 29.72 0 01-12.66 19.52 36.16 36.16 0 01-13.93 5.5 41.29 41.29 0 01-15.1 0A37.16 37.16 0 0144 95.74a39.3 39.3 0 01-14.5-19.42 38.31 38.31 0 010-24.63 39.25 39.25 0 019.18-14.91A37.17 37.17 0 0176.13 27a34.28 34.28 0 0113.64 8q5.83-5.8 11.64-11.63c2-2.09 4.18-4.08 6.15-6.22A61.22 61.22 0 0087.2 4.59a64 64 0 00-42.61-.38z"></path>
                  </svg>
                </a>
              </span>
              <span className="text-base font-medium text-white md:text-xl ContactContent">
                <a href="mailto:prajanacharya11@gmail.com">
                  prajanacharya11@gmail.com
                </a>
              </span>
            </div>
            <div className="flex flex-row Address lg:gap-x-5 gap-x-2">
              <span className="svgIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 md:w-8 md:h-8 fill-white stroke-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
              </span>
              <span className="text-base font-medium text-white md:text-xl ContactContent">
                15-23 Orara Street, Waitara, 2077
              </span>
            </div>
            <div className="flex flex-row Phone lg:gap-x-5 gap-x-2">
              <span className="svgIcon">
                <a href="tel:+61451725559">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 md:w-8 md:h-8 fill-white stroke-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                    />
                  </svg>
                </a>
              </span>
              <span className="text-lg font-medium text-white md:text-xl ContactContent">
                <a href="tel:+61451725559">+61451725559</a>
              </span>
            </div>
          </div>

          {/* Send Me A message */}
          <div className="flex flex-col w-full p-2 xl:w-4/6 bg-background-600 contactFormContainer">
            <div className="px-5 stylingContactFormContainer">
              <h3 className="mb-3 text-lg font-bold text-white underline md:text-xl underline-offset-8 decoration-white">
                Send me a message:
              </h3>
              <form className="rounded">
                <div className="mb-4 ">
                  <label
                    htmlFor="name"
                    className="text-sm text-white md:text-base "
                  >
                    Name
                  </label>
                  <input
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none md:text-base focus:outline-none focus:shadow-outline"
                    id="name"
                    type="text"
                    placeholder="Enter your name here"
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="text-sm text-white md:text-base "
                  >
                    Email
                  </label>
                  <input
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none md:text-base focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    placeholder="Enter your email here"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
                <div className="mb-4 ">
                  <label
                    htmlFor="message"
                    className="text-sm text-white md:text-base "
                  >
                    Message
                  </label>
                  <textarea
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none md:text-base focus:outline-none focus:shadow-outline"
                    id="message"
                    placeholder="Enter your message here"
                    rows={5}
                    required
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  />
                </div>
                {error && error.length != 0 ? (
                  <p className="inline-block p-1 mb-2 text-sm font-bold tracking-wide text-white bg-red-600">
                    {error}
                  </p>
                ) : (
                  ""
                )}
                <ReCAPTCHA
                  sitekey="6LfoX6QkAAAAAHZ16n8wz5xfFoEQn-PYr1WgSHXs"
                  onChange={(value) => setCaptchaResponse(value)}
                  ref={recaptchaRef}
                  size={"normal"}
                  onExpired={() => {
                    // @ts-ignore
                    recaptchaRef.current.reset(); // here
                  }}
                />
                {/* <br /> */}
                <div className="flex justify-center py-4 ">
                  <button
                    onClick={handleFormSubmit}
                    className="flex flex-row items-center px-5 py-2 text-white border border-transparent rounded-full bg-primary-500 border-1 hover:bg-primary-600 disabled:cursor-not-allowed"
                  >
                    {messageSending ? (
                      <svg
                        className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      ""
                    )}

                    {messageSending ? "Sending Message ..." : "SEND MESSAGE"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMe;
