import { useEffect, useState, useRef } from "react";

type Props = {
  currentElementIndexInViewPort: number;
};

const Nav = (props: Props) => {
  const [mobileMenu, openMobileMenu] = useState(false);

  const mobileTileContainerDiv = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleWindowClick = (e: any) => {
      if (
        mobileTileContainerDiv.current &&
        !mobileTileContainerDiv.current.contains(e.target) //doesnot contain when clicked outside tile
      ) {
        return mobileMenu ? openMobileMenu(false) : null;
      }
    };

    window.addEventListener("click", handleWindowClick);

    return () => window.removeEventListener("click", handleWindowClick);
  }, [mobileMenu]);

  const handleNavClick = (id: number) => {
    for (let i = 0; i < 5; i++) {
      document.getElementById("menu-" + i)?.classList.remove("active");
    }
    document.getElementById("menu-" + id)?.classList.add("active");
  };

  return (
    <header className="flex flex-row items-center justify-between w-full px-8 py-4 border-white App-header border-b-none">
      <div className="flex justify-center Logo">
        <span className="text-2xl font-bold tracking-widest text-primary-300 Name_first_letter">
          Prajan
        </span>
      </div>
      <div className="flex-row justify-center hidden Nav-Menu lg:flex lg:flex-1 gap-x-9">
        <a
          href="#Home"
          className={
            props.currentElementIndexInViewPort === 0 ? "text-primary-300" : "text-white hover:text-primary-200"
          }
          id="menu-0"
          onClick={() => handleNavClick(0)}
        >
          HOME
        </a>
        <a
          href="#About"
          className={
            props.currentElementIndexInViewPort === 1 ? "text-primary-300" : "text-white hover:text-primary-200"
          }
          id="menu-1"
          onClick={() => handleNavClick(1)}
        >
          ABOUT ME
        </a>
        <a
          href="#Experience"
          className={
            props.currentElementIndexInViewPort === 2 ? "text-primary-300" : "text-white hover:text-primary-200"
          }
          id="menu-2"
          onClick={() => handleNavClick(2)}
        >
          EXPERIENCE
        </a>
        <a
          href="#Portfolio"
          className={
            props.currentElementIndexInViewPort === 3 ? "text-primary-300" : "text-white hover:text-primary-200"
          }
          id="menu-3"
          onClick={() => handleNavClick(3)}
        >
          PORTFOLIO
        </a>
        <a
          href="#ContactMe"
          className={
            props.currentElementIndexInViewPort === 4 ? "text-primary-300" : "text-white hover:text-primary-200"
          }
          id="menu-4"
          onClick={() => handleNavClick(4)}
        >
          CONTACT ME
        </a>
      </div>

      <div className="flex NavButtonContainer gap-x-5">
        <div className="flex justify-center downloadCVButtonContainer gap-x-4">
          <a
            href="https://prajanacharya.com.np/CV/Prajan-Acharya-Resume.pdf" target="_blank"
            className="flex flex-row px-5 py-1 text-white border border-transparent rounded-full bg-primary-500 border-1 hover:bg-primary-600 gap-x-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6 md:hidden"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
              />
            </svg>
            <span className="hidden md:inline">DOWNLOAD</span> CV
          </a>
        </div>
        {/* Mobile menu button */}
        <div className="relative flex lg:hidden">
          <button
            type="button"
            onClick={() => openMobileMenu(!mobileMenu)}
            ref={mobileTileContainerDiv}
            className="text-lg text-white hover:text-gray-100 focus:outline-none focus:text-gray-100"
            aria-label="toggle menu"
            id="mobileTile"
          >
            <svg viewBox="0 0 24 24" className="fill-current h-9 w-9">
              <path
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>

          {/* */}
          <div
            className={`MobileNavMenu flex flex-col absolute right-1 top-14 bg-primary-500 min-w-max ${
              mobileMenu ? "" : "hidden"
            }`}
          >
            <a
              href="#Home"
              className="py-1 text-white border-b border-white px-7 hover:bg-primary-600"
            >
              HOME
            </a>
            <a
              href="#About"
              className="py-1 text-white border-b border-white px-7 hover:bg-primary-600"
            >
              ABOUT ME
            </a>
            <a
              href="#Experience"
              className="py-1 text-white border-b border-white px-7 hover:bg-primary-600"
            >
              EXPERIENCE
            </a>
            <a
              href="#Portfolio"
              className="py-1 text-white border-b border-white px-7 hover:bg-primary-600"
            >
              PORTFOLIO
            </a>
            <a
              href="#ContactMe"
              className="py-1 text-white border-b border-white px-7 hover:bg-primary-600"
            >
              CONTACT ME
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Nav;
