import { Ref, useState } from "react";

type Props = {
  sectionRef?: Ref<HTMLDivElement>;
};

const Experience = (props: Props) => {
  // usestate
  const [activeCompany, setActiveCompany] = useState(1);
  return (
    <div
      className="border-t border-b border-zinc-800 bg-background-750 lg:h-fit lg:min-h-screen ContactMe"
      id="Experience"
      ref={props.sectionRef}
    >
      <div className="container flex flex-col items-center w-full h-full p-3 mx-auto md:w-4/5 pb-7 lg:pb-7">
        <div
          className="flex py-12 text-2xl text-white md:py-20 md:text-4xl"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
        >
          <h1 className="relative tracking-widest ExperienceHeading PageHeading">
            My Work Experience
          </h1>
        </div>

        <div
          className="container w-full h-full mx-auto shadow-2xl"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-delay="100"
          data-aos-duration="700"
          data-aos-easing="ease-in-sine"
        >
          <div className="flex flex-row flex-wrap text-sm border-b border-white md:text-base gap-x-2 CompanyNames max-h-min">
            <span
              className={
                "p-2 px-4 font-semibold tracking-wider text-white hover:cursor-pointer " +
                (activeCompany === 1
                  ? "active border-b-4 border-primary-700 bg-primary-500"
                  : "hover:bg-gray-600")
              }
              id="Company-1"
              onClick={() => setActiveCompany(1)}
            >
              finao
            </span>
            <span
              className={
                "p-2 px-4 font-semibold tracking-wider text-white hover:cursor-pointer " +
                (activeCompany === 2
                  ? "active border-b-4 border-primary-700 bg-primary-500"
                  : "hover:bg-gray-600")
              }
              id="Company-2"
              onClick={() => setActiveCompany(2)}
            >
              Build Change
            </span>
            <span
              className={
                "p-2 px-4 font-semibold tracking-wider text-white hover:cursor-pointer " +
                (activeCompany === 3
                  ? "active border-b-4 border-primary-700 bg-primary-500"
                  : "hover:bg-gray-600")
              }
              id="Company-3"
              onClick={() => setActiveCompany(3)}
            >
              Islington College
            </span>
          </div>
          <div
            className={
              "p-3 shadow-2xl JobExperienceDetails max-h-min bg-background-600 " +
              (activeCompany === 1 ? "" : "hidden")
            }
            id="FinaoExperience"
          >
            <div className="flex py-1 pl-1 text-sm italic text-white gap-x-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
              <span>North Sydney, New South Wales, Australia</span>
            </div>
            <h1 className="pb-2 pl-2 text-base font-bold tracking-wide text-white md:text-lg">
              Full Stack Developer (<i>June 2023 - Present</i>){" "}
            </h1>
            <ul className="text-sm text-white list-disc list-outside pl-7 JobResponsibilities md:text-base">
              <li>
                Developing robust and feature enriched web application using Laravel framework.
              </li>
              <li>
                Maintaining the existing web applications of the organization.
              </li>
              <li>
                Developing and maintaining the front-end of the web applications using tools such as HTML, CSS, JS, jQuery, Angular.js and so on.
              </li>
              <li>
                Designing, developing and maintaining REST APIs.
              </li>
              <li>
                Designing, developing and maintaining of MySQL database.
              </li>
              <li>
                Working closely with project manager, scrum master and co-developers in an Agile/Scrum environment to deliver
                services to the clients on the basis of their requirements. 
              </li>
              <li>
                Keeping up with the latest web development tools and technologies.
              </li>
            </ul>
          </div>
          <div
            className={
              "p-3 shadow-2xl max-h-min bg-background-600 " +
              (activeCompany === 2 ? "" : "hidden")
            }
            id="BuildChangeExperience"
          >
            <div className="flex py-1 pl-1 text-sm italic text-white gap-x-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
              <span>Denver, Colorado, USA (Remote)</span>
            </div>
            <h1 className="pb-2 pl-2 text-base font-bold tracking-wide text-white md:text-lg">
              Web Application Developer (<i>July 2019 - October 2021</i>){" "}
            </h1>
            <ul className="text-sm tracking-wide text-white list-disc list-outside pl-7 JobResponsibilities md:text-base">
              <li>Developing web applications and services used by government entities, stakeholders and people of several countries such as Indonesia, Phillipines, Dominica, Colombo and Nepal</li>
              <li>Developing modern & robust web applications in Laravel.</li>
              <li>
                Designing as well as developing the frontend of web applications
                using React.
              </li>
              <li>
                Writing resusable frontend and backend components across
                multiple projects.
              </li>
              <li>
                Designing, developing, testing and documenting the REST APIs to
                be used by the frontend developers and mobile application
                developers.
              </li>
              <li>
              Designing and development of relational databases such as MySQL and
              PostgreSQL and non-relational databases such as MongoDB.
              </li>
              <li>
                Carrying out several backend and User Interface (UI) tests to
                enhance the functionality of the applications ensuring that the
                integrations run smoothly
              </li>
              <li>
                Participating in the meetings with the clients, presenting them
                the works carried out and scaling the projects based on their
                feedback.
              </li>
              <li>
                Deploying the web applications in AWS using various AWS services
                such as EC2, RDS, S3, Elastic Beanstalk, Amplify, Route 53 and
                so on.
              </li>
              <li>
                Maintaining the existing web applications of the organization.
              </li>
              <li>
                Keeping up-to-date with latest technology trends and best
                practices in web development.
              </li>
              <li>
                Co-ordinating with the co-developers (frontend, backend and
                mobile app) and project manager in a fast-paced agile
                environment to deliver projects smoothly
              </li>
              <li>
                Documenting the development process and architecture as well as
                developing the user manuals for the clients.
              </li>
            </ul>
          </div>
          <div
            className={
              "p-3 shadow-2xl JobExperienceDetails max-h-min bg-background-600 " +
              (activeCompany === 3 ? "" : "hidden")
            }
            id="IslingtonExperience"
          >
            <div className="flex py-1 pl-1 text-sm italic text-white gap-x-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
              <span>Kathmandu, Nepal</span>
            </div>
            <h1 className="pb-2 pl-2 text-base font-bold tracking-wide text-white md:text-lg">
              Teaching Assistant (<i>August 2018 - January 2019</i>){" "}
            </h1>
            <ul className="text-sm text-white list-disc list-outside pl-7 JobResponsibilities md:text-base">
              <li>
                Assisting lecturers and tutors in programming classes of
                bachelor’s first year students.
              </li>
              <li>
                Evaluating students on the basis of their
                performance/assignments.
              </li>
              <li>
                Working in a team for development of College Attendance
                Management System
              </li>
              <li>
                Designing and developing relational database for College
                Attendance Management System
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
