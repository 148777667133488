import React, { useState, useRef, useEffect } from "react";
import { Scrollspy } from "@makotot/ghostui";
import "./App.css";
import Home from "./components/Home";
import Nav from "./components/Nav";
import AboutMe from "./components/AboutMe";
import ContactMe from "./components/ContactMe";
import Experience from "./components/Experience";
import Portfolio from "./components/Portfolio";
import Footer from "./components/Footer";
import AOS from 'aos'; 
import 'aos/dist/aos.css'; // You can also use <link> for styles

AOS.init({
  once: true,
});



function App() {

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('loader')?.remove();
    }, 1000);
  }, []);

  const sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  
    return (
     
      <div className="relative overflow-x-hidden App">
        <Scrollspy sectionRefs={sectionRefs} offset={-150}>
  
          {({ currentElementIndexInViewport }) => (
              <div>
                  <div className="fixed top-0 left-0 right-0 z-10 shadow-lg bg-zinc-700 NavigationContainer">
                      <div className="container relative mx-auto">
                          <Nav currentElementIndexInViewPort = {currentElementIndexInViewport} />
                      </div>
                  </div>
  
                  <Home sectionRef={sectionRefs[0]}  />
  
                  <AboutMe sectionRef={sectionRefs[1]} />
  
                  <Experience sectionRef={sectionRefs[2]} />
  
                  <Portfolio sectionRef={sectionRefs[3]} />
  
                  <ContactMe sectionRef={sectionRefs[4]} />
  
                  <Footer />
              </div>
                  
          )}
        </Scrollspy>
      </div>
    );
 
  
}

export default App;
