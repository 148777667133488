import { Ref } from 'react'

type Props = {
    sectionRef?: Ref<HTMLDivElement>
}

const Portfolio = (props: Props) => {
// usestate


return (
<div className="border-b border-zinc-800 lg:h-fit lg:min-h-screen bg-background-700 Portfolio" id="Portfolio" ref={props.sectionRef}>
    <div className='container flex flex-col items-center h-full p-4 mx-auto pb-7 lg:p-0 md:w-4/5'>
        <div className="flex py-12 text-2xl text-white md:py-20 md:text-4xl"
        data-aos="fade-right"
        data-aos-delay="100"
        data-aos-duration="700"
        data-aos-easing="ease-in-out">
            <h1 className='relative tracking-widest PortfolioHeading PageHeading'>Portfolio</h1>
        </div>
        <div className='grid justify-center grid-cols-1 lg:grid-cols-3 md:grid-cols-2 ProjectContainers gap-x-2 gap-y-2 lg:px-3'>
            <div className='relative overflow-hidden border-2 border-black shadow-2xl ProjectBox hover:cursor-pointer group' id="EazyCare" 
            data-aos="fade-left"
            data-aos-delay="500"
            data-aos-duration="700"
            data-aos-easing="ease-in-out">
                <a href="https://eazycare.prajanacharya.com.np/" target="__blank" rel="noopener noreferrer">
                    <span className='absolute top-0 right-0 px-2 font-mono font-light text-white z-[1] bg-opacity-90 ProjectBadge bg-primary-500'>
                        Featured
                    </span>
                    <img src="/images/eazycare.png" alt="Eazy Care Home Page" className='transition duration-1000 ease-in-out ProjectImage group-hover:scale-105' />
                    <div className="absolute bottom-0 left-0 right-0 flex flex-col font-mono text-center text-white transition duration-1000 ease-in-out opacity-100 md:text-base md:opacity-0 ProjectDetails bg-background-700 group-hover:opacity-100">
                        <span className=''>EazyCare (Ecommerce)</span>
                        <span className='text-sm'>Laravel</span>
                    </div>
                </a>  
            </div>
            <div className='relative overflow-hidden border-2 border-black shadow-2xl ProjectBox hover:cursor-pointer group' id="EazyCare" 
            data-aos="fade-left"
            data-aos-delay="500"
            data-aos-duration="700"
            data-aos-easing="ease-in-out">
                <a href="https://laravuechat.prajanacharya.com.np/" target="__blank" rel="noopener noreferrer">
                    <span className='absolute top-0 right-0 px-2 font-mono font-light text-white z-[1] bg-opacity-90 ProjectBadge bg-primary-500'>
                        Featured
                    </span>
                    <img src="/images/laravuechat.png" alt="Eazy Care Home Page" className='transition duration-1000 ease-in-out ProjectImage group-hover:scale-105' />
                    <div className="absolute bottom-0 left-0 right-0 flex flex-col font-mono text-center text-white transition duration-1000 ease-in-out opacity-100 md:text-base md:opacity-0 ProjectDetails bg-background-700 group-hover:opacity-100">
                        <span className=''>LaraVueChat (Chat Application)</span>
                        <span className='text-sm'>Laravel / Vue / Tailwind CSS</span>
                    </div>
                </a>  
            </div>
            <div className='relative overflow-hidden border-2 border-black shadow-2xl ProjectBox hover:cursor-pointer group' id="EazyCare"
            data-aos="fade-left"
            data-aos-delay="600"
            data-aos-duration="700"
            data-aos-easing="ease-in-out">
                <a href="#" target="__blank" rel="noopener noreferrer">
                    <img src="/images/portfolio.png" alt="My Portfolio Home Page" className='transition duration-1000 ease-in-out ProjectImage group-hover:scale-105' />
                    <div className="absolute bottom-0 left-0 right-0 flex flex-col font-mono text-center text-white transition duration-1000 ease-in-out opacity-100 md:opacity-0 ProjectDetails bg-background-700 group-hover:opacity-100 bg-opacity-90">
                        <span className='text-base '>Portfolio Website</span>
                        <span className='text-sm'>React / Tailwind CSS</span>
                    </div>
                </a>  
            </div>
            <div className='relative overflow-hidden border-2 border-black shadow-2xl ProjectBox hover:cursor-pointer group' id="EazyCare"
            data-aos="fade-left"
            data-aos-delay="700"
            data-aos-duration="700"
            data-aos-easing="ease-in-out">
                <a href="https://larasoc.prajanacharya.com.np/login" target="__blank" rel="noopener noreferrer">
                    <img src="/images/socialite.png" alt="Socialite Application Home Page" className='transition duration-1000 ease-in-out ProjectImage group-hover:scale-105' />
                    <div className="absolute bottom-0 left-0 right-0 flex flex-col font-mono text-center text-white transition duration-1000 ease-in-out opacity-100 md:opacity-0 ProjectDetails bg-background-700 group-hover:opacity-100 bg-opacity-90">
                        <span className='text-base '>Laravel Socialite Login</span>
                        <span className='text-sm'>Laravel</span>
                    </div>
                </a>  
            </div>
            <div className='relative overflow-hidden border-2 border-black shadow-2xl ProjectBox hover:cursor-pointer group' id="EazyCare"
            data-aos="fade-left"
            data-aos-delay="800"
            data-aos-duration="700"
            data-aos-easing="ease-in-out">
                <a href="https://github.com/prajan11" target="__blank" rel="noopener noreferrer">
                    <img src="/images/github-header-image.png" alt="Github Profile Banner" className='transition duration-1000 ease-in-out ProjectImage group-hover:scale-105' />
                    <div className="absolute bottom-0 left-0 right-0 flex flex-col font-mono text-center text-white transition duration-1000 ease-in-out opacity-100 md:opacity-0 ProjectDetails bg-background-700 group-hover:opacity-100 bg-opacity-90">
                        <span className='text-base '>View more on GitHub</span>
                        <span className='text-sm'>https://github.com/prajan11</span>
                    </div>
                </a>  
            </div>



            
        </div>
    </div>

</div>
);
}

export default Portfolio;
