import  { Ref, useEffect, useRef, useState } from 'react'

// ..


type Props = {
    sectionRef?: Ref<HTMLDivElement>
}



const AboutMe = (props: Props) => {
// usestate


return (
<div className="lg:h-fit lg:min-h-screen bg-background-700 About" id="About" ref={props.sectionRef}>
    <div className='container flex flex-col items-center visible h-full p-3 pb-5 mx-auto lg:pb-5'>
        <div className={'flex py-12 text-2xl text-white md:py-20 md:pt-20 md:text-4xl AboutMeHeadingContainer'} 
            data-aos="fade-right"
            data-aos-delay="100"
            data-aos-duration="700"
            data-aos-easing="ease-in-out">
            <h1 className='relative tracking-widest AboutMeHeading PageHeading'>About Me</h1>
        </div>
        <div className='flex flex-col mx-auto text-sm tracking-wide text-white md:text-base sm:w-full md:w-4/5 lg:w-3/5 AboutMeContent'
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="700"
        data-aos-easing="ease-in-out">
            <p className='mb-5 tracking-wide text-center lg:mb-10'>
                Hello, my name is <span className="font-semibold">Prajan Acharya</span>. I am a <span className="font-semibold">Web Application Developer</span> and 
                currently residing in Sydney, NSW, Australia.
                I have completed my Bachelor's in Information
                Technology [<span className="font-semibold">BSc (Hons) Computing</span>] from Islington College,
                affiliated to London Metropolitan University.
            </p>
            <p className='mb-5 tracking-wide text-center lg:mb-10'>
                I am an enthusiastic web developer with <span className="font-semibold">more than two years of experience </span> in
                web development. I have experience in building, deploying
                and maintaining web applications and APIs. I am also an <a href="https://www.credly.com/badges/3365ca2d-1644-48d4-9901-d67f96fa0006/public_url" target="__blank" rel="noreferrer nofollow" className='underline underline-offset-4'>AWS Certified Cloud Practitioner.</a>
            </p>
            <p className='mb-5 tracking-wide text-center lg:mb-10'>
                Currently, I am looking for a fresh start as a web developer in this new
                country. Whether
                you want to hire me or want to have chat with me, I would love to connect with you.
            </p>
            <p className='mb-10 tracking-wide text-center'>
                Here are some few technologies that I have been working with recently:
            </p>

            <div
                className='flex flex-row flex-wrap px-5 py-5 rounded-lg shadow-custom shadow-background-800 bg-background-700 SkillsFlexBox gap-x-6 gap-y-5'
                data-aos="flip-down"
                data-aos-delay="700"
                data-aos-duration="700"
                data-aos-easing="ease-in-out">

                <span title='HTML 5' id="skillIcon">
                    <i className="text-3xl md:text-5xl devicon-html5-plain-wordmark colored"></i>
                </span>

                <span title='CSS 3' id="skillIcon">
                    <i className="text-3xl md:text-5xl devicon-css3-plain-wordmark colored"></i>
                </span>

                <span title='JavaScript' id="skillIcon">
                    <i className="text-3xl md:text-5xl devicon-javascript-plain colored"></i>
                </span>

                <span title='Bootstrap' id="skillIcon">
                    <i className="text-3xl md:text-5xl devicon-bootstrap-plain colored"></i>
                </span>


                <span title='Tailwind CSS' id="skillIcon">
                    {/* <i className="text-3xl md:text-5xl devicon-tailwindcss-original-wordmark colored"></i> */}
                    <i className="text-3xl md:text-5xl devicon-tailwindcss-plain colored"></i>
                </span>


                <span title='PHP' id="skillIcon">
                    <i className="text-3xl md:text-5xl devicon-php-plain colored"></i>
                </span>


                <span title='Laravel' id="skillIcon">
                    <i className="text-3xl md:text-5xl devicon-laravel-plain-wordmark colored"></i>
                </span>

                <span title='JQuery' id="skillIcon">
                    <i className="text-3xl md:text-5xl devicon-jquery-plain-wordmark colored"></i>
                </span>

                <span title='React JS' id="skillIcon">
                    <i className="text-3xl md:text-5xl devicon-react-original-wordmark colored"></i>
                </span>

                <span title='Vue JS' id="skillIcon">
                    <i className="text-3xl md:text-5xl devicon-vuejs-plain-wordmark colored"></i>
                </span>

                <span title='MySQl' id="skillIcon">
                    {/* <i className="text-3xl md:text-5xl devicon-mysql-plain-wordmark colored"></i> */}
                    <i className="text-3xl md:text-5xl devicon-mysql-plain colored"></i>
                </span>


                <span title='PostgreSQL' id="skillIcon">
                    <i className="text-3xl md:text-5xl devicon-postgresql-plain-wordmark colored"></i>
                </span>


                <span title='MongoDB' id="skillIcon">
                    <i className="text-3xl md:text-5xl devicon-mongodb-plain-wordmark colored"></i>
                </span>

                <span title='Git' id="skillIcon">
                    <i className="text-3xl md:text-5xl devicon-git-plain-wordmark colored"></i>
                </span>

            </div>
            <div className="flex flex-row justify-center mt-8 aboutUsContactMeButton"
            data-aos="fade-right"
            data-aos-delay="400"
            data-aos-duration="500"
            data-aos-easing="ease-out">
                <a href="#ContactMe"
                    className="px-6 py-2 text-white border border-transparent rounded-full bg-primary-500 border-1 hover:bg-primary-600 hover:cursor-pointer">CONTACT
                    ME</a>
            </div>

        </div>
    </div>

</div>
);
}

export default AboutMe;
